import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Gallery from '../../components/gallery.jsx';
import Embed from '../../components/embed.jsx';
import Container from '../../components/container.jsx';
import sc5 from '../../static/img/work/squaresAndCircles/sc1.png';
import sc1 from '../../static/img/work/squaresAndCircles/gif/1.gif';
import sc2 from '../../static/img/work/squaresAndCircles/gif/2.gif';
import sc3 from '../../static/img/work/squaresAndCircles/gif/3.gif';
import sc4 from '../../static/img/work/squaresAndCircles/gif/4.gif';
export const _frontmatter = {
  "key": 3,
  "title": "Squares & Circles",
  "type": "audiovisual",
  "date": "2019",
  "description": "Squares and Circles is a film in which we research and reflect about the absurdity of gender and all the rules we impose upon ourselves. In this film we got the challange to work almoast only with found footage. It resulted in a film in which we used editing as a form of clay with which we can sculpt within the editing room.",
  "image": "../../static/img/work/squaresAndCircles/sc5.png",
  "video": "https://www.youtube.com/embed/0ItZhpv4pto",
  "alt": "film still",
  "runningTime": "8:54",
  "rating": 4
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery width={75} mdxType="Gallery">
    <div>
         <h2>Experiments</h2>
        <p>During the proces we experimented a lot with video as collage.</p>
    </div>
    <img src={sc1} alt="film still" />
    <img src={sc5} alt="film still" />
    <img src={sc2} alt="film still" />
    <img src={sc4} alt="film still" />
    <img src={sc3} alt="film still" />
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      